import React, { PureComponent } from 'react';
import Switch from "react-switch";
import Slider from "react-slick";
import Slide from "./../elements/slide.js"
import "./../css/slick.css";
import "./../css/slick-theme.css";
import "./../css/highsandlows.css";

export default class Counter extends PureComponent {
  constructor() {
    super();
    this.state = { checked: false, highlights:true};
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checked) {
    this.setState({ checked });
    this.setState({highlights:!this.state.highlights});
  }

  render() {
    var settings = {
  infinite: true,
  speed: 4000,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  vertical: true,
  autoplay: true,
  centerMode: true,
  adaptiveHeight: true,
};
    if(this.state.highlights){
      return(
        <div>
        HIGHLIGHTS<Switch css = {{marginLeft:`1rem`, marginRight:`1rem`}} onChange={this.handleChange} offColor = {"#7d4aa3"} onColor={"#101010"} checked={this.state.checked} uncheckedIcon = {false} checkedIcon = {false }/>LOWLIGHTS
        {/*THESE ARE THE HIGHLIGHTS*/}
        <Slider {...settings}>
        <Slide color = "#7d4aa3" text = "I have given speeches to crowds with 10k+ in attendance."/>
        <Slide color = "#7d4aa3" text = "I have performed at Wembley Stadium."/>
        <Slide color = "#7d4aa3" text = "One time, I uprooted my entire life and moved to Hawaii for 6 months."/>
        <Slide color = "#7d4aa3" text = "I graduated from a major that had a 88% dropout rate."/>
        <Slide color = "#7d4aa3" text = "I have been to 23 countries and counting."/>
        <Slide color = "#7d4aa3" text = "I have never lost a hackathon."/>
        <Slide color = "#7d4aa3" text = "I'm 6 foot 8 inches tall, so I can reach all those top shelf items."/>
        <Slide color = "#7d4aa3" text = "My friends say I ask the best questions."/>
          </Slider>
        </div>
      )
    }
    else {
    return (
      <div>
      HIGHLIGHTS<Switch css = {{marginLeft:`1rem`, marginRight:`1rem`}} onChange={this.handleChange} offColor = {"#7d4aa3"} onColor={"#101010"} checked={this.state.checked} uncheckedIcon = {false} checkedIcon = {false }/>LOWLIGHTS
        {/*THESE ARE THE LOW*/}
        <Slider css = {{backgroundColor:`#D0D0D0`}}{...settings}>
        <Slide color = "#101010" text = "I have bankrupted a company before."/>
        <Slide color = "#101010" text = "It took me a year to redesign my website."/>
        <Slide color = "#101010" text = "I forget to floss a lot."/>
        <Slide color = "#101010" text = "I have trouble going down more than one step at a time."/>
        <Slide color = "#101010" text = "I don't know how to ride a bicycle."/>
        <Slide color = "#101010" text = "When I was in 1st grade a rat ran up my leg in class and I was known as rat boy."/>
        <Slide color = "#101010" text = "Risk aversion rules my life at times."/>
          </Slider>
      </div>);
  }
}
}
