import React from "react"
import ReactVivus from 'react-vivus';

const Slide = props =>
{
  return(
    <div css = {{height:`10rem`}}>
      <p css = {{color:props.color, fontSize:`x-large`}}> {props.text}</p>
    </div>);
}
export default Slide
