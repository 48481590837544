import React from "react"
// import Layout2 from "../components/layout2"
// import ReactVivus from 'react-vivus';
// import Button from './../elements/button.js'
// import Disqus from './../elements/disqus.js'
// import Applause from './../elements/applause.js'
// import EmailSignup from './../components/emailsignup.js'
// import HighsAndLows from "./../elements/highsandlows.js"
import Img from 'gatsby-image'
import { StaticQuery, graphql } from "gatsby"
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

export default class Responsive extends React.Component {
  render(){
    return(
<div css={{'@media (max-width: 500px)':{gridTemplateColumns: `repeat(1, 1fr)`,marginTop:`10rem`}, '@media (max-width: 750px)':{marginLeft:`5%`, marginRight:`5%`, marginTop:`10rem`}, maxWidth:`700px`,marginLeft:`auto`,marginRight:`auto`, display:`grid`, gridTemplateColumns: `repeat(3, 1fr)`, gridGap: `2rem`, marginTop:`10rem`}}>
<ParallaxProvider>
<StaticQuery
  query={graphql`{
    AboutPhotos:
        allFile(filter: {relativeDirectory: {eq: "img/about/bad"}}, sort: {fields: name, order: DESC}) {
    edges {
      node {
        childImageSharp {
          fluid(maxWidth:500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    }
    }
  `}
  render={data => (
    data.AboutPhotos.edges.map(({ node }, i) => (
      <Parallax x={[i%2==0 ? `50%` : `-50%`, i%2==0 ? `-50%` : `50%`]} y={[i%2==0 ? `50%` : `-50%`, i%2==0 ? `-50%` : `50%`]}>
      <Img css={{transform:Math.random()>0.5 ? `scale(1.3)` : `scale(1.5)`}} fluid = {node.childImageSharp.fluid}> </Img>
      </Parallax>
    )
  )
)
}
/>

</ParallaxProvider>
</div>
);
}
}
